<template>
  <div class="p-5 text-center">
    <h4>Something went wrong.</h4>
    <h5>
      You are not authorized to access this portal. Please contact at
      <a class="theme-font-bold" href="mailto:support@esolutionsgroup.ca">
        support@esolutionsgroup.ca</a
      >
    </h5>
  </div>
</template>
<script>
export default {
  name: 'Unauthorized'
}
</script>
